.button_enabled {
    width: 100%;
    padding: 20px 24px;
    background: black;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    text-transform: uppercase;
    border-radius: 47px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.button_enabled:hover{
    background: #272728;
}
.button_enabled:focus{
    outline: none;
}
.button_disabled {
    width: 100%;
    padding: 20px 24px;
    background: black;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    text-transform: uppercase;
    border-radius: 47px;
    transition: all .3s ease-in-out;
    cursor: no-drop;
}