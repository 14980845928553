
.skills_section{
    padding: 0px 13%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.skills_head h2{
    font-size: 30px;
    margin-bottom: -5px;
}
.skills_head h2 span{
    color: #e74d06;
}
.skills_main{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 60px;
    grid-column-gap: 50px;
}
.skills_main .skill_bar .info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  0 10px;
}
.skill_bar .info p:nth-child(1){
    font-weight: 600;
    color: #272728;
    font-size: 22px;
    font-family: 'Kanit', sans-serif;
}
.skill_bar .info p:nth-child(2){
    font-weight: 500;
}
.skill_bar .bar{
    width: 100%;
    height: 7px;
    background-color: rgba(0, 0, 0, 0.144);
    border-radius: 25px;
    margin-top: 5px;
    position: relative;
}
.skill_bar .bar span{
    width: 50%;
    height: 100%;
    position: absolute;
    background-color: #5E3AEE;
    border-radius: 25px;
}
.skill_bar .bar .f1{
    width: 90%;
    animation: f1 2s;
}
@keyframes f1 {
    0%{
        width: 0%;
    }100%{
         width: 90%;
     }
}
.skill_bar .bar .f2{
    width: 95%;
    animation: f2 2s;
}
@keyframes f2 {
    0%{
        width: 0%;
    }100%{
         width: 95%;
     }
}
.skill_bar .bar .f3 {
    width: 90%;
    animation: f3 2s;
}
@keyframes f3 {
    0%{
        width: 0%;
    }100%{
         width: 90%;
     }
}
.skill_bar .bar .f4{
    width: 85%;
    animation: f4 2s;
}
@keyframes f4 {
    0%{
        width: 0%;
    }100%{
         width: 85%;
     }
}
.skill_bar .bar .f5{
    width: 87%;
    animation: f5 2s;
}
@keyframes f5 {
    0%{
        width: 0%;
    }100%{
         width: 87%;
     }
}
.skill_bar .bar .f6{
    width: 85%;
    animation: f6 2s;
}
@keyframes f6 {
    0%{
        width: 0%;
    }100%{
         width: 85%;
     }
}