.custom_top__bar {
    position: relative;
}
.custom_top__bar:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 84px;
    height: 3px;
    background: #272728;
}
