.custom__scroller ::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.custom__scroller ::-webkit-scrollbar-track {
    background: #FDFDFD;
}

/* Handle */
.custom__scroller ::-webkit-scrollbar-thumb {
    background: #D4D4D4;
}

/* Handle on hover */
.custom__scroller ::-webkit-scrollbar-thumb:hover {
    background: #D4D4D4;
}
