@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        scroll-behavior: smooth;
    }
    body {
        font-family: 'Poppins', sans-serif;
        margin: 0px;
        box-sizing: border-box;
    }
    h1 {
        font-family: 'Kanit', sans-serif;
    }
    h4 {
        font-weight: 600;
        color: #272728;
        font-family: 'Kanit', sans-serif;
    }
    p {
        font-size: 16px;
        color: #9E9FA5;
        font-family: 'Poppins', sans-serif;

    }
    li {
        list-style: none;
    }
    .font_kanit {
        font-family: 'Kanit', sans-serif;
    }
    .font_poppins {
        font-family: 'Poppins', sans-serif;
    }
    .text-25 {
        font-size: 25px !important;
    }
    .text-72 {
        font-size: 72px;
        font-weight: 600;
    }

    .mt-30 {
        margin-top: 30px !important;
    }

    .custom_bg {
        width: 100%;
        height: 100%;
        background: linear-gradient(113.2deg, #ECF3FE 1.09%, #F7F3FE 97.18%);
    }
}
