.custom_after{
    position: relative;
    color: #5E3AEE;
    font-size: 16px;
    font-weight: 500;

}
.custom_after:after {
    position: absolute;
    content: "";
    top: 0;
    left: -60px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #E8E8E8;
    z-index: 1;
}
.custom__hover {
    padding-left: 50px;
    border-left: 1px solid #E8E8E8;
}
.custom__hover:last-child{
    border-left: 1px solid transparent;
}
.custom__hover:hover .custom_after:after {
    background-color: #5E3AEE;
    transition: all 0.3s;
}