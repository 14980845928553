.custom_bg {
    width: 100%;
    height: 100%;
    background: linear-gradient(113.2deg, #ECF3FE 1.09%, #F7F3FE 97.18%);
}
.animation-scroll{
    animation: animate 5s linear infinite;
    opacity: 1;
    cursor: pointer;
}

.change_text__animate:after{
    content: '';
    animation: animate_text infinite 20s;
}

@keyframes animate_text {
    0% {
        content: 'OSCE OSCP CREST Certified';
    }
    20% {
        content: 'Security Engineer';
    }
    40% {
        content: 'Cyber Security Instructor';
    }
    60% {
        content: 'Author';
    }
    80% {
        content: 'OWASP iGoat Lead';
    }
    100% {
        content: 'OSCE OSCP CREST Certified';
    }
}

@keyframes animate {
    0% {
        transform: translateY(5px) scale(1);
    }
    50% {
        transform: translateY(-5px) scale(1);
    }
    100% {
        transform: translateY(5px) scale(1);
    }


}