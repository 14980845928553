.custom_border__source-product {
    position: relative;
    text-align: center;
}
.custom_border__source-product:after {
    position: absolute;
    content: "";
    top: 0;
    left: calc(100% - 54%);
    width: 84px;
    height: 3px;
    text-align: center;
    background: #272728;
}

@media (min-width: 375px) {
    .custom_border__source-product:after {
        left: calc(100% - 58%);
    }
}
@media (min-width: 540px) {
    .custom_border__source-product:after {
        left: calc(100% - 58%);
    }
}
@media (min-width: 728px) {
    .custom_border__source-product:after {
        left: calc(100% - 56%);
    }
}
@media (max-width: 984px) {
    .custom_border__source-product:after {
        left: calc(100% - 56%);
    }
}
@media (min-width: 1174px) {
    .custom_border__source-product:after {
        left: calc(100% - 54%);
    }
}