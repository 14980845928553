.gallery {
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-width: 33%;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
}
.gallery .pics {
    transition: all 350ms ease;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    cursor: pointer;
    margin: 0px 10px 40px 10px;
    z-index: 1;
    position: relative;
    overflow: hidden;
}
.absolute_icon {
    position: absolute;
    top: 38%;
    left: 41%;
    z-index: 3;
    width: 100%;
    height: 100%;
    visible: hidden;
    opacity: 0;
}

.gallery .pics:hover:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.4;
    border-radius: 15px;
    z-index: 2;
}

.gallery .pics:hover .absolute_icon {
    visible: visible;
    opacity: 1;
}

.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .5);
    transition: opacity .3s ease, visibility .3s ease, transform .3s ease-in-out;
    visibility: hidden;
    transform: scale(0);
    opacity: 0.2;
    overflow: hidden;
    z-index: 99;
}
.model.open{
    visibility: visible;
    background: rgba(0, 0, 0, .5);
    transform: scale(1);
}
.model.open img{
    transform: scale(1.2);
}
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}
.model.open .close_btn{
    position: fixed;
    top: 20px;
    right: 20px;
    width: 36px;
    height: 36px;
    border: 1px solid #fff;
    border-radius: 100%;
    padding: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    cursor: pointer;
}
.close_btn span {
    display: flex;
    align-items: center;
    justify-content: center;
}
.close_btn {
    position: fixed;
}

@media (min-width: 375px) {
    .gallery {
        column-count: 1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-width: 100%;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
    }
    .absolute_icon {
        top: 40%;
        left: 44%;
    }
}

@media (min-width: 540px) {
    .gallery {
        column-count: 2;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-width: 50%;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
    }
    .absolute_icon {
        top: 36%;
        left: 36%;
    }
}

@media (min-width: 768px) {
    .gallery {
        column-count: 3;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-width: 33%;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
    }
    .absolute_icon {
        top: 36%;
        left: 38%;
    }
}

@media (min-width: 984px) {
    .gallery {
        column-count: 3;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-width: 33%;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
    }
    .absolute_icon {
        top: 40%;
        left: 40%;
    }
}
