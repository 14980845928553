.custom_navbar__css {
    background-color: rgba(255,255,255,0.94);
    box-shadow: 0 0 15px 0 rgb(216 236 254 / 75%);
    backdrop-filter: blur(3px);
    transition: all 0.6s ease;
}
.nav  > li {
    margin-bottom: 16px;
}
.custom_navbar__css__active {
    background-color: white;
    border-radius: 10px;
    transition: all 0.6s ease;
    box-shadow: 0 0 15px 0 rgb(216 236 254 / 75%);
    backdrop-filter: blur(3px);
    transition: all 0.6s ease;
    width: 100%;
    height: 100vh;
}